<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit,
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form>
          <a-row>
            <a-col :sm="5">
              <e-input
                title="IP地址"
                :labelCol="8"
                :valueCol="15"
                v-model:value="queryParams.IP"
              />
            </a-col>
            <a-col :sm="5">
              <e-input
                title="位置"
                :labelCol="6"
                :valueCol="17"
                v-model:value="queryParams.Location"
              />
            </a-col>
            <a-col :sm="5">
              <e-input
                title="客户姓名"
                :labelCol="8"
                :valueCol="15"
                v-model:value="queryParams.CustomerName"
              />
            </a-col>
            <a-col :sm="5">
              <e-input
                title="客户手机"
                :labelCol="8"
                :valueCol="15"
                v-model:value="queryParams.CustomerMobile"
              />
            </a-col>
            <a-col :col="4">
              <a-button type="primary" @click="getDataList(1)"> 查询 </a-button>
              <a-button
                type="primary"
                @click="reset(() => resetFun())"
                danger
                style="margin-left: 10px"
              >
                重置
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.id"
          :scroll="{ y: height, x: width }"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ (page.page - 1) * queryParams.Limit + index + 1 }}
            </template>

            <template v-if="column.key === 'customerName'">
              <div class="username">
                <img
                  :src="record.avatar"
                  :alt="record.customerName"
                  v-if="record.avatar"
                />
                <span>{{ record.customerName }}</span>
                <span v-if="record.customerSex == 1" class="sex man">
                  <ManOutlined />
                </span>
                <span v-else-if="record.customerSex == 2" class="sex feman">
                  <WomanOutlined />
                </span>
                <span v-else class="sex">
                  <ManOutlined />
                </span>
              </div>
            </template>

            <template v-if="column.key === 'bookTime'">
              {{$filters.formatDate(record.bookTime, 'MM-DD HH:mm')}}
            </template>

            <template v-if="column.key === 'sellingPrice'">
              ¥{{ record.sellingPrice }}
            </template>

            <template v-if="column.key === 'rate'">
              {{
                (
                  ((record.unitPrice * record.peopleCount - record.price) /
                    record.price) *
                  100
                ).toFixed(2)
              }}
              %
            </template>

            <template v-if="column.key === 'action'">
              <a-tooltip>
                <template #title>添加黑名单</template>
                <a @click.stop="addBlack(record)">
                  <icon-font type="icon-4fayuanheimingdan272636" />
                </a>
              </a-tooltip>
            </template>
          </template>
        </a-table>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:current="page.page"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['20', '40', '100', '200']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {
  WomanOutlined,
  ManOutlined
} from '@ant-design/icons-vue'
import { BookRecordClass, BlackMobileClass } from '@/apis/order'
import page from '@/mixins/page'

const api = new BookRecordClass()
const black = new BlackMobileClass()
export default defineComponent({
  mixins: [page],
  components: {
    WomanOutlined,
    ManOutlined
  },
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 60
      },
      {
        title: '姓名',
        key: 'customerName',
        dataIndex: 'customerName',
        width: 150
      },
      {
        title: '手机号',
        key: 'customerMobile',
        dataIndex: 'customerMobile',
        width: 120
      },
      {
        title: '餐标',
        key: 'comboPrice',
        dataIndex: 'comboPrice',
        width: 60
      },
      {
        title: '人数',
        key: 'peopleCount',
        dataIndex: 'peopleCount',
        width: 60
      },
      {
        title: 'IP',
        key: 'ip',
        dataIndex: 'ip',
        width: 150
      },
      {
        title: '位置',
        key: 'location',
        dataIndex: 'location'
      },
      {
        title: '预订时间',
        key: 'bookTime',
        dataIndex: 'bookTime',
        width: 120
      },
      {
        title: '创建时间',
        key: 'createTime',
        dataIndex: 'createTime',
        width: 180
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        width: 60
      }
    ]
    return {
      columns,
      loading,
      height,
      width
    }
  },
  created () {
    this.getDataList(1)
  },
  methods: {
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit

      api.gets(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 20
      }

      this.getDataList(1)
    },

    addBlack (record) {
      black.create({ mobile: record.customerMobile }).then(() => {
        this.$message.success('添加给名单成功')
      })
    },

    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    }
  }
})
</script>

<style lang="less" scoped></style>
